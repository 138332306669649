import React, { Suspense, useState, useEffect } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, useGLTF } from '@react-three/drei';

interface ModelProps {
    url: string;
    onLoadComplete: () => void;
}

const Model: React.FC<ModelProps> = ({ url, onLoadComplete }) => {
    const { scene } = useGLTF(url);

    useEffect(() => {
        // Simulate loading delay for testing
        const delay = setTimeout(() => onLoadComplete(), 20); // 2-second delay for spinner
        return () => clearTimeout(delay);
    }, [onLoadComplete]);

    return (
        <group position={[0, -2.0, 0.75]} scale={[2.5, 2.5, 2.5]}>
            <primitive object={scene} />
        </group>
    );
};

interface ModelViewerProps {
    modelUrl: string;
}

// Loading Spinner Component
const LoadingSpinner: React.FC = () => {
    return (
        <div
            style={{
                position: 'absolute',
                opacity: 1,
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                color: 'white',
                fontSize: '1.5rem',
                fontWeight: 'bold',
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                padding: '1rem',
                borderRadius: '8px',
                border: '2px solid white',
                textAlign: 'center',
            }}
        >
            Loading...
        </div>
    );
};

const ModelViewer: React.FC<ModelViewerProps> = ({ modelUrl }) => {
    const [isLoading, setIsLoading] = useState(true);

    const handleLoadComplete = () => {
        console.log('Model has loaded');
        setIsLoading(false);
    };

    return (
        <div style={{width: '100vw', height: '100vh', position: 'relative'}}>
            {/* Show the spinner during loading */}
            {isLoading && <LoadingSpinner/>}
            <Canvas style={{ width: '100vw', height: '100vh' }}>
                {/* Add lights */}
                <ambientLight intensity={1.5}/>
                <directionalLight position={[10, 10, 5]} intensity={2}/>

                {/* Suspense to manage model loading */}
                <Suspense fallback={null}>
                    <Model url={modelUrl} onLoadComplete={handleLoadComplete}/>
                </Suspense>
                <OrbitControls
                    enablePan={false}
                    enableZoom={false}
                    minPolarAngle={Math.PI / 2}
                    maxPolarAngle={Math.PI / 2}
                />
            </Canvas>
        </div>
    );
};

export default ModelViewer;
