import React from 'react';
import ModelViewer from './ModelViewer';

const App: React.FC = () => {
    // Extract the query parameter value from the URL
    const queryParams = new URLSearchParams(window.location.search);
    const modelId = queryParams.get('model');

    // Construct the GLB URL based on the model ID
    const glbUrl = modelId ? `https://models.readyplayer.me/${modelId}.glb` : '';

    // Handle the case where modelId is not present in the URL
    if (!glbUrl) {
        return <div>No model ID provided in the URL</div>;
    }

    return (
        <div>
            <ModelViewer modelUrl={glbUrl} />
        </div>
    );
};

export default App;
